import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        themes: {
            // 기존 값
            light: {
                primary: "#1a2759", 
                secondary: "#2d76a7"
            },

            // 연두색 테스트
            // light: {
            //     primary: colors.lightGreen, 
            //     secondary: colors.lightGreen['lighten-4']
            // },
        },
    },  
})
