export const routes = [
    {
        path: '/',
        component: () => import(/* webpackChunkName: "Common" */ './layouts/front/index.vue'),
        children: [
            {
                path: '/',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/home.vue')
            },
            {
                path: 'introduce',
                component: () => import(/* webpackChunkName: "Common" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'greetings',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/introduce/greetings.vue')
                    },
                    {
                        path: 'certification',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/introduce/certification.vue')
                    },
                    {
                        path: 'map',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/introduce/map.vue')
                    },
                    
                ]
            },
            {
                path: 'business',
                component: () => import(/* webpackChunkName: "Common" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'manufacture',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/business/manufacture.vue')
                    },
                    {
                        path: 'rf',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/business/rf.vue')
                    },
                    {
                        path: 'additive',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/business/additive.vue')
                    },
                    
                ]
            },
            {
                path: 'product',
                component: () => import(/* webpackChunkName: "Common" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'light',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/product/light.vue')
                    },
                    {
                        path: 'gas',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/product/gas.vue')
                    }
                ]
            },
            {
                path: 'documents',
                component: () => import(/* webpackChunkName: "Common" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'manual',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/documents/manual.vue')
                    },
                    {
                        path: 'catalog',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/documents/catalog.vue')
                    }
                ]
            },
            {
                path: 'service',
                component: () => import(/* webpackChunkName: "Common" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'qna',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/service/qna.vue')
                    },
                    {
                        path: 'notice',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/service/notice.vue')
                    }
                ]
            },
            {
                path: 'partner',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/partner.vue')
            },
            {
                path: 'contact',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/contact.vue')
            },
        ]
    },

    // 관리자
    {
        path: '/admin',
        component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/admin/index.vue'),
        children: [
            // Auth
            {
                path: 'auth',
                component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'login',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/auth/login.vue')
                    },
                    {
                        path: 'logout',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/auth/logout.vue')
                    },
                ]
            },

            // 사업소개 관리
            {
                path: 'business',
                component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'list',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/business/list.vue')
                    },
                    {
                        path: 'update',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/business/update.vue')
                    }
                ]
            },

            // 제품소개 관리
            {
                path: 'product',
                component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'list',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/product/list.vue')
                    },
                    {
                        path: 'update',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/product/update.vue')
                    }
                ]
            },

            // Q&A 관리
            {
                path: 'qna',
                component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'list',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/qna/list.vue')
                    },
                    {
                        path: 'update',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/qna/update.vue')
                    },
                    {
                        path: 'write',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/qna/write.vue')
                    },
                ]
            },

            // 공지사항 관리
            {
                path: 'notice',
                component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'list',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/notice/list.vue')
                    },
                    {
                        path: 'update',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/notice/update.vue')
                    },
                    {
                        path: 'write',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/notice/write.vue')
                    },
                ]
            },

            // 관리자 정보수정
            {
                path: 'update',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/admin/update.vue')
            }
        ]
    },
]