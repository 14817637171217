import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // 사용자 계정
        ctng_user: {
            is_logined: false,
            user_id: "",
            nickname: "",
            email: "",
            profile_image: "",
            type: "",
            status: "",
            created: ""
        },

        // 관리자 계정
        ctng_admin: {
            is_logined: false,
            user_id: ""
        },

        // 언어
        language: "kr"
    },

    getters: {

    },

    mutations: {
        // 사용자 로그인
        login: (state, payload) => {
            state.ctng_user.is_logined = true
            state.ctng_user.user_id = payload.user_id
            state.ctng_user.nickname = payload.nickname
            state.ctng_user.email = payload.email
            state.ctng_user.profile_image = payload.profile_image
            state.ctng_user.type = payload.type
            state.ctng_user.status = payload.status
            state.ctng_user.created = payload.created
        },

        // 사용자 로그아웃
        logout: (state) => {
            state.ctng_user.is_logined = false
            state.ctng_user.user_id = ""
            state.ctng_user.nickname = ""
            state.ctng_user.email = ""
            state.ctng_user.profile_image = ""
            state.ctng_user.type = ""
            state.ctng_user.status = ""
            state.ctng_user.created = ""
        },

        // 관리자 로그인
        admin_login: (state, payload) => {
            state.ctng_admin.is_logined = true
            state.ctng_admin.user_id = payload.user_id
        },

        // 관리자 로그아웃
        admin_logout: (state) => {
            state.ctng_admin.is_logined = false
            state.ctng_admin.user_id = ""
        },

        // 언어 변경
        language_change: (state, payload) => {
            if(state.language == "kr"){
                state.language = "en"
            }else{
                state.language = "kr"
            }
        },
    },

    actions: {

    },

    modules: {

    },

    plugins: [
        createPersistedState({
            paths: [
                'ctng_user',
                'ctng_admin',
                'language'
            ]
        })
    ]
})
